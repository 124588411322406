/* .title {
    font-size: 300px;
} */

.leftGrid {
    font-size: 10rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 120px;
    text-align: right;
    letter-spacing: -.08em;
    line-height: .9em;
    text-shadow: .15ch 15px var(--shadow), 0 -2px 0 var(--highlight);
}

.leftGridPartner {
    font-size: 10rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 100px;
    text-align: right;
    letter-spacing: -.08em;
    line-height: .9em;
    text-shadow: .15ch 15px var(--shadow), 0 -2px 0 var(--highlight);
}

.rightGrid {
    color: white;
    text-align: left;
    font-size: 2em;
    font-weight: 600;
}

.leftGridSolution {
    color: rgb(201, 84, 7);
    text-align: left;
    font-size: 2em;
    font-weight: 600;
    padding: 0 0 0 100px;
}




.rightGridSolution {
    font-size: 7rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 15px;
    text-align: right;
    letter-spacing: -.08em;
    line-height: .9em;
}


.solution {
    background-color: antiquewhite;
}

/* .service {
    margin-bottom: 20px;
} */

.service_last {
    text-transform: uppercase;
}

hr {
  display: block;
  margin-top: 0.3em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

 @media(max-width: 900px) {
    .leftGrid{
        font-size: 5rem;
        padding: 0 50px;
    }

    .rightGridSolution{
        font-size: 4rem;
        padding: 0;
        text-align: center;
        letter-spacing: -.08em;
        line-height: .9em;
        margin-top: 50px;
    }

    .rightGrid {
        text-align: right;
        font-size: 2em;
        font-weight: 600;
        padding: 0 50px;
        margin-top: 100px;
    }

    .leftGridSolution {
        text-align: left;
        font-size: 1.5em;
        font-weight: 600;
        padding: 10px;
    }
 }


 .parallax {
     overflow: hidden;
     letter-spacing: -2px;
     line-height: 0.8;
     margin: 0;
     white-space: nowrap;
     display: flex;
     flex-wrap: nowrap;
 }

 .parallax .scroller {
     font-weight: 600;
     text-transform: uppercase;
     font-size: 84px;
     display: flex;
     white-space: nowrap;
     display: flex;
     flex-wrap: nowrap;
 }

 .parallax span {
     display: block;
     margin-right: 30px;
 }


 #navigation {
     width: 100%;
     height: 60px;
     position: fixed;
     top: 0;
     right: 0;
     left: 0;
     z-index: 15;
     background-color: rgba(0, 183, 255, 0);
     color: rgba(21, 21, 21, 1);
     display: flex;
     flex-direction: row;
     align-items: center;
     /* justify-content: space-around; */

     padding: 0 24px;

     font-weight: 700;
     font-size: 13px;
     letter-spacing: 0.0200rem;

     &.hidden {
         top: -60px;
     }

     #logo {
         background: rgb(255, 255, 255);
         width: 80px;
         height: 30px;
         border-radius: 30px;
         margin-right: 30px;
         padding-top: 10px;
         padding-right: 10px;
         font-size: 20px;
     }
 }

 .mainSection {
    margin-top: 2em;
    height: calc(100vh - 200px);
 }
 .partnerSection {
    /* border: 1px solid rgba(0, 0, 0, 0.264); */
    padding: 10px;
    border-radius: 5px;
    background-color: antiquewhite;
    box-shadow: 9px 10px 7px -3px rgba(0, 0, 0, 0.1);
    height: 400px;
 }

@media(max-width: 900px) {
    .leftGridPartner {
            font-size: 3rem;
            padding: 0;
            margin-top: 10px;
            font-weight: 500;
            text-transform: uppercase;
            /* padding: 0 100px; */
            text-align: right;
            justify-self: right;
            margin-right: 10px;
        }

        .title{
            padding: 0;
                font-size: 0.6em;
                letter-spacing: 0em;
                text-align: left;
                transform: translateX(0px);
        }
}